export const useFormatString = (
  str: string,
  payload: {
    replace?: [string, string];
    replaceAll?: [string, string];
    replaceAllStartEnd?: [[any, any], [any, any]];
  }
) => {
  if (!str) return "";

  let string = "";

  // replace
  if (payload?.replace) {
    string = str.replace(payload.replace[0], payload.replace[1]);
  }

  // replaceAll
  if (payload?.replaceAll) {
    string = str.replaceAll(payload.replaceAll[0], payload.replaceAll[1]);
  }

  //replaceAll with start and end
  if (payload?.replaceAllStartEnd) {
    const newString = str.replaceAll(
      payload.replaceAllStartEnd[0][0],
      payload.replaceAllStartEnd[0][1]
    );
    string = newString.replaceAll(
      payload.replaceAllStartEnd[1][0],
      payload.replaceAllStartEnd[1][1]
    );
  }

  return string;
};
